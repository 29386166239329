import request from '@/utils/apiRequest'

export const stsToken = () =>
    request({
        url: '/tool/get-ali-oss-sts',
        method: 'post',
        data: {},
    })
export const txStsToken = () =>
    request({
        url: '/tool/get-tx-cos-sts',
        method: 'post',
        data: {},
    })
export const stsTokenImage = () =>
    request({
        url: '/tool/get-ali-oss-sts',
        method: 'post',
        data: {},
    });