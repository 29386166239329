import { createApp } from 'vue'
import App from './App.vue'
import installElementPlus from '@/config/element'
import router from './router'
import store from './store'
import './assets/css/icon.css'
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import {authCheckSave} from "@/utils/make";

const app = createApp(App).use(router).use(store)
for (const [key,component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key,component)
}
installElementPlus(app)
authCheckSave().then(()=>{
    app.mount('#app')
})
