export default [
    {
        path: '/agent/white',
        name: "AgentWhiteList",
        meta: {
            title: "代充白名单"
        },
        component: () => import("@/views/agent/agentWhite/List")
    },
    {
        path: '/agent/account-change',
        name: "AgentAccountChangeList",
        meta: {
            title: "游戏账号换绑"
        },
        component: () => import("@/views/agent/accountChange/List")
    },
    {
        path: '/agent/promote-wallet',
        name: "AgentPromoteWalletList",
        meta: {
            title: "平台币记录"
        },
        component: () => import("@/views/agent/promoteWallet/List")
    },
]