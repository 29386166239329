import request from '@/utils/apiRequest'

export const login = (telephone, password) =>
    request({
        url: '/admin/login/login',
        method: 'post',
        data: {telephone, password},
    })

export const info = () =>
    request({
        url: '/login/info',
        method: 'post',
        data: {},
    })

export const changePass = (data) =>
    request({
        url: '/login/change-pass',
        method: 'post',
        data: data,
    })
export const qrLogin = (tmp_code) =>
    request({
        url: '/login/qr-code-login',
        method: 'post',
        data: {tmp_code},
    })
